import React from 'react';
import _ from 'lodash';

import {htmlToReact, classNames} from '../utils';

export default class SectionSubscribeCta extends React.Component {
    render() {
        let section = _.get(this.props, 'section', null);
        return (
            <section id={_.get(section, 'section_id', null)} className="block cta-block bg-accent outer">
              <div className="inner-large">
                <div className="grid">
                  <div className="cell block-content">
                    {_.get(section, 'title', null) && (
                    <h2 className="block-title">{_.get(section, 'title', null)}</h2>
                    )}
                    {_.get(section, 'subtitle', null) && (
                    <p className="block-subtitle">
                      {htmlToReact(_.get(section, 'subtitle', null))}
                    </p>
                    )}
                  </div>
                  <div className="cell widget">  
                    <form name={_.get(section, 'capture_form', null) + 'Form'} action={'/thanks-' + _.get(section, 'capture_form', null)} method="POST" data-netlify-honeypot="bot-field" data-netlify="true" id="subscribe-form" className="subscribe-form">
                      <div className="screen-reader-text">
                        <label id="subscribe-form-bot-label">Don't fill this out if you're human: <input name="bot-field" aria-labelledby="subscribe-form-bot-label" /></label>
                      </div>
                      <div className="form-row">
                        <label id="subscribe-form-email-label">
                          <span className="screen-reader-text">Email address</span>
                          <input className="subscribe-email" aria-labelledby="subscribe-form-email-label" type="email" name="email" placeholder="Enter Email Address..."/>
                        </label>
                      </div>
                      <input type="hidden" aria-label="subscribe-form-name-label" name="form-name" value={_.get(section, 'capture_form', null) + 'Form'} />
                      <button className={classNames('button', {'secondary': _.get(section, 'primary', null) !== true})} type="submit">{_.get(section, 'button_text', null)}</button>
                    </form>
                  </div>

                </div>
              </div>
            </section>
        );
    }
}