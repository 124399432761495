import React from 'react';
import _ from 'lodash';

export default class SectionVideo extends React.Component {
    render() {
        let section = _.get(this.props, 'section', null);
        return (
            <section id={_.get(section, 'section_id', null)} className={'block text-block bg-' + _.get(section, 'background', null) + ' outer'}>

              <div className="inner-large">
                <div className="grid">
                  <div className="cell block-content">
              
                   <iframe title={_.get(section, 'youtube_id', null)} style={{border: '0'}} width="560" height="315" src={'https://www.youtube.com/embed/'+ _.get(section, 'youtube_id', null)} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                   </div>
                </div>
              </div>
            </section>
        );
    }
}
